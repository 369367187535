import React from 'react';
import Retool from 'react-retool';
import './App.css';

function App() {
  return (
      <div className={"container"}>
        <Retool
            url={process.env.REACT_APP_RETOOL_URL}
            classname={"retool"}
        />
      </div>
  );
}

export default App;
